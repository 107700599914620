<template>
  <div>
    <invoice
      @updated="
        (_) => {
          dialog = _;
        }
      "
      @added="
        (_) => {
          dialog = _;
          getInvoices();
        }
      "
      :type="type"
      :dialog="dialog"
    />
    <div class="d-flex align-content-center justify-space-between">
      <div class="d-flex align-center">
        <h1 v-if="typeFrom == null" class="mb-3">
          {{ $t(type.replace("Orders", " Orders")) }}
        </h1>
        <v-chip class="mx-3">{{ $t("all") }} : {{ total }}</v-chip>
      </div>
      <!-- v-if="$service.hideFrom((v) => [v == 0])" -->
      <v-btn
        v-if="typeFrom == null"
        color="secondary"
        x-large
        @click="dialog = !dialog"
      >
        <v-icon class="mr-2">mdi-plus</v-icon>
        {{ $t("new-order") }}
      </v-btn>
    </div>
    <v-card :loading="loading" class="transparent">
      <v-row>
        <v-col cols="3" v-if="typeFrom == null" style="padding-bottom: 0">
          <label for="se">{{ $t("date") }}</label>
          <v-menu
            ref="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            class="white"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-detials="auto"
                solo
                filled
                class="mt-2"
                v-model="options.StartDate"
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker ref="picker" v-model="options.StartDate">
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="
                  () => {
                    options.StartDate = '';
                  }
                "
              >
                Reset
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3" v-if="typeFrom == null">
          <label for="comp">{{ $t("company") }}</label>
          <v-autocomplete
            class="mt-2"
            @change="getInvoices()"
            clearable
            v-model="options.CompanyId"
            :items="companies"
            :loading="loading"
            solo
            filled
            item-value="id"
            item-text="name"
            :label="$t('company')"
            hide-details="auto"
          />
        </v-col>
        <!-- <v-col cols="3" v-if="typeFrom == null" style="padding-bottom: 0">
          <label for="se">Province </label>
          <v-select
            v-model="options.ProvinecId"
            label="Provinec"
            class="mt-2"
            solo
            filled
            :items="provinecs"
            item-text="name"
            :disabled="loading"
            item-value="id"
          ></v-select>
        </v-col> -->
        <v-col cols="3" v-if="typeFrom == null" style="padding-bottom: 0">
          <label for="se">{{ $t("order-number") }} </label>
          <v-text-field
            hide-detials="auto"
            :label="$t('order-number')"
            id="se"
            class="mt-2"
            solo
            filled
            :loading="loading"
            clearable
            v-model="options.Search"
            append-icon="mdi-magnify"
            type="search"
          ></v-text-field>
        </v-col>

        <!-- v-chip-group statues -->
        <v-col
          cols="12"
          v-if="typeFrom == null && $store.getters.role != 'SalesTeamLeader'"
          style="padding-top: 0"
        >
          <v-chip-group
            v-model="status"
            @change="getInvoices()"
            :loading="loading"
            active-class="success"
          >
            <v-chip
              large
              label
              v-for="(item, index) in statues"
              :key="index"
              filter
              :value="item.key"
            >
              {{ item.name }}
            </v-chip>
          </v-chip-group>
        </v-col>
        <v-col cols="12">
          <div class="d-flex justify-end mb-2">
            <download-excel :fetch="getAllOrders" :fields="fields">
              <v-btn color="primary">
                <v-icon>mdi-download</v-icon>
                {{ $t("download-excel") }}
              </v-btn>
            </download-excel>
          </div>
          <v-data-table
            disable-pagination
            :headers="getHeaders()"
            :items="invoices"
            :no-data-text="$t('no-data')"
            hide-default-footer
            :server-items-length="total"
            @click:row="
              (row) => {
                openOrder(row);
              }
            "
          >
            <template v-slot:item.created="{ item }">
              <span dir="ltr">
                {{ $service.formatDate(new Date(item.created), false) }}
              </span>
            </template>
            <template v-slot:item.status="{ item }">
              <template v-if="!item.isDelete">
                <v-chip
                  label
                  :color="state.color"
                  v-for="state in newStatus"
                  :key="state.key"
                  v-show="state.key == item.status ? true : false"
                >
                  {{ state.name }}
                </v-chip>
              </template>
              <v-chip
                v-if="item.isDelete == true"
                class="ma-2 canceled"
                color="error"
                text-color="white"
                label
              >
                Deleted
              </v-chip>
            </template>
            <template v-slot:item.totalPrice="{ item }">
              {{ $service.formatCurrency(item.totalPrice) }}
            </template>
            <template v-slot:item.quickOrder="{ item }">
              {{ item.quickOrder ? "Yes" : "No" }}
            </template>
            <template v-slot:item.actions>
              No
            </template>
          </v-data-table>
          <div class="d-flex justify-center">
            <v-pagination
              v-model="options.PageIndex"
              :length="parseInt(total / options.PageSize) + 1"
            />
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import Invoice from "./Add.vue";
import Pusher from "pusher-js";
export default {
  components: { Invoice },
  props: {
    typeFrom: {
      type: String,
      default: null,
    },
    fromId: {
      type: String,
      default: null,
    },
  },
  computed: {
    role() {
      return this.$store.state.roles[this.$store.getters.role];
    },
  },
  data() {
    return {
      // for excel
      fields: {
        "No.": "orderNO",
        "Order Date": {
          key: "created",
          callback: (v) => {
            return this.$service.formatDate(new Date(v.created), false);
          },
        },
        Pharmacy: "pharmacy.fullName",
        "Total Price": "totalPrice",
        "Total Price After Discount": "totalPriceAfterDiscount",
        discount: "discount",
        "Total Number Of Unit": "totalNumberOfUnit",
        "Total Number Of Unit Free": "totalNumberOfUnitFree",
        Status: {
          key: "status",
          callback: (v) => {
            return this.backStatus.find((v2) => v.status == v2.key) != null
              ? this.backStatus.find((v2) => v.status == v2.key).name
              : "";
          },
        },
        "Created By": "userCreatedFullName",
      },
      company: {},
      options: {
        CompanyId: "",
        AgentId: "",
        Search: "",
        PageSize: 30,
        ProvinecId: "",
        PageIndex: 1,
        OfficeId: this.$store.state.userInfo.office.id,
        StartDate: "",
      },
      total: 0,
      searchAgent: "",
      invoices: [],
      agents: [],
      type: "PharmacyOrders",
      status: [0, 1, 2, 3],
      companies: [],
      loading: true,
      toTransit: {},
      provinecs: [],
      stores: [],
      searchStore: "",
      storeId: "",
      transitDialog: false,
      dialog: false,
      backStatus: [],
      statues: [],
      newStatus: [
        {
          key: 0,
          name: this.$t('pending'),
          color: "warning",
        },
        {
          key: 1,
          name: "Pending",
          color: "warning",
        },
        {
          key: 2,
          name: "Rejected",
          color: "error",
        },
        {
          key: 3,
          name: "Approval",
          color: "success",
        },
        {
          key: 4,
          name: "Rejected",
          color: "error",
        },
        {
          key: 5,
          name: "Approval",
          color: "success",
        },
        {
          key: 6,
          name: "Rejected",
          color: "error",
        },
        {
          key: 7,
          name: "Done",
          color: "success",
        },
        {
          key: 8,
          name: "Pending",
          color: "warning",
        },
        {
          key: 9,
          name: "Fowarded",
          color: "primary",
        },
        {
          key: 10,
          name: "Fowarded",
          color: "primary",
        },
        {
          key: 11,
          name: "Obtained",
          color: "success",
        },
        {
          key: 12,
          name: "Full Obtained",
          color: "success",
        },
        {
          key: 13,
          name: "Canceled",
          color: "error",
        },
        {
          key: -1,
          name: "Canceled",
          color: "error",
        },
      ],
      // headers: ,
    };
  },
  mounted() {
    Pusher.logToConsole = false;
    this.pusher = new Pusher("b4d809eca77d687d414a", {
      cluster: "ap2",
    });
    this.channel = this.pusher.subscribe("01orderChannelSecret");
    this.channel.bind("01orderEventSecret", (data) => {
      this.invoices.unshift(data);
    });
    if (this.typeFrom != null) {
      this.type = this.typeFrom;
    }
    this.$parent.$on("updateOrders", this.getInvoices());
  },
  created() {
    this.getInvoices2();
    
    console.log("id", this.$store.state.userInfo);
    if (this.typeFrom != null) {
      this.type = this.typeFrom;
    }
    this.$http.get("/Companies", {
        params: {
          OfficeId: this.$store.state.userInfo.office.id,
        },
      }).then((res) => {
        this.companies = res.data.result;
        this.company = this.companies[0];
        this.companies.unshift({ id: "", name: "All" });
        this.loading = false;
        if (
          this.$route.name == "accounts-orders" ||
          this.$route.name == "dashboard-accounts"
        ) {
          this.statues = [
            { key: [], name: this.$t('all') },
            { key: [0, 1, 3], name: this.$t('pending') },
            { key: [1, 3, 5], name: this.$t('approval') },
            { key: [4, 2, 6], name: this.$t('rejected') },
            { key: [7], name: this.$t('done') },
            { key: [9], name: this.$t('fowarded') },
            { key: [-1, 13], name: this.$t('canceld') },
          ];
          this.status = this.statues[0].key;
        } else if (this.$route.name == "warehouses-orders") {
          this.statues = [];
          this.status = [5];
        }
        if (this.role >= 1) {
          this.options.CompanyId = this.$store.state.userInfo.userData.companyId;
        }
      });
  },
  methods: {
    openOrder(row) {
      console.log(row);
      if (!row.isDelete) {
        if (row.status !== -1) {
          this.$router.push({
            name: "accounts-order",
            params: {
              type: this.type,
              id: row.id,
            },
          });
        }
      }
    },
    actionCheck(item) {
      if (item.statues == -1 && item.isDel == true) {
        return false;
      }
    },
    async getAllOrders() {
      try {
        this.loading = true;
        let res = await this.$http.get(`/${this.type}`, {
          params: {
            PageSize: this.total,
          },
        });
        this.loading = false;
        return res.data.data;
      } catch (e) {
        this.loading = false;
        this.$store.commit("UPDATE_SNACKBAR", true);
        this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message);
      }
    },
    statusColor(item) {
      let status = this.newStatus.find((x) => x.key.includes(item.status));

      if (status) {
        let colors = {
          0: "warning",
          1: "blue",
          3: "blue lighten-1",
          2: "error",
          4: "error lighten-1",
          5: "success",
          6: "error lighten-2",
          7: "success lighten-1",
          "-1": "error",
        };
        return colors[status.key[0]];
      } else {
        return "";
      }
    },
    getHeaders() {
      let name = "pharmacy.fullName";
      if (this.type == "PharmacyOrders") {
        name = "pharmacy.fullName";
      } else if (this.type == "RepositoryOrders") {
        name = "repository.fullName";
      } else if (this.type == "DoctorOrders") {
        name = "doctor.fullName";
      }
      let region = "";
      if (this.type == "PharmacyOrders") {
        region = "pharmacy.regionName";
      } else if (this.type == "RepositoryOrders") {
        region = "repository.regionName";
      } else if (this.type == "DoctorOrders") {
        region = "doctor.regionName";
      }
      let headers = [
        {
          text: this.$t("no"),
          align: "start",
          sortable: false,
          value: "orderNO",
        },
        { text: this.$t("company"), value: "companyName" },
        { text: this.$t("created-by"), value: "userCreatedFullName" },
        {
          text: this.$t(this.type.replace("Orders", "")),
          value: name,
        },
        {
          text: this.$t("region"),
          value: region,
        },
        { text: this.$t("price"), value: "totalPrice" },
      ];
      if (this.status.indexOf(9) > -1) {
        headers.push({
          text: "Teamleader",
          value: "forwardedTeamLeaderFullName",
        });
      }
      headers.push({ text: this.$t("date"), value: "created" });
      headers.push({ text: this.$t("status"), value: "status" });
      if (this.status == 7) {
        headers.push({ text: this.$t('actions'), value: "actions" });
      }
      return headers;
    },
    openTransit(item) {
      this.transitDialog = true;
      this.toTransit = item;
    },
    transit() {
      this.transitDialog = false;
      this.$http
        .put(`/${this.type}/IsTransit/` + this.toTransit.id, {
          note: "",
          storeId: this.storeId,
        })
        .then(() => {
          this.getInvoices();
        });
    },
    getInvoices() {
      this.loading = true;
      this.invoices = [];
      if (this.typeFrom != null) {
        this.options[this.type.replace("Orders", "") + "Id"] = this.fromId;
        this.options.Statuses = "[]";
      }
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null) {
              if (key != "Statuses") {
                return `${key}=${val}`;
              }
            }
          })
          .join("&");

      if (this.$store.getters.role == "SalesTeamLeader") {
        this.options.ForwardedTeamLeaderId = this.$store.state.userData.id;
        this.status = [];
      }
      this.status.forEach((element) => {
        filter += "&Statuses=" + element;
      });
      this.$http.get(`/${this.type}` + filter.replace("?&", "?")).then((prod) => {
          this.invoices = prod.data.data;
          this.invoices.map((item) => {
            if (item.isDelete && item.statues == 0) {
              item.isDel = true;
            }
            return item;
          });
          this.loading = false;
          this.total = prod.data.count;
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
    getInvoices2() {
      this.loading = true;
      this.invoices = [];
      if (this.typeFrom != null) {
        this.options[this.type.replace("Orders", "") + "Id"] = this.fromId;
        this.options.Statuses = "[]";
      }
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null) {
              if (key != "Statuses") {
                return `${key}=${val}`;
              }
            }
          })
          .join("&");

      if (this.$store.getters.role == "SalesTeamLeader") {
        this.options.ForwardedTeamLeaderId = this.$store.state.userData.id;
        this.status = [];
      }
      // this.status.forEach((element) => {
      //   filter += "&Statuses=" + element;
      // });
      this.status = [];
      this.$http.get(`/${this.type}` + filter.replace("?&", "?")).then((prod) => {
          this.invoices = prod.data.data;
          this.invoices.map((item) => {
            if (item.isDelete && item.statues == 0) {
              item.isDel = true;
            }
            return item;
          });
          this.loading = false;
          this.total = prod.data.count;
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
  },
  watch: {
    typeFrom() {
      if (this.typeFrom != null) {
        this.type = this.typeFrom;
      }
      this.getHeaders();
      this.getInvoices();
    },
    $route: {
      handler() {
        this.type = this.$route.params.type;
        if (this.typeFrom != null) {
          this.type = this.typeFrom;
        }
        this.getHeaders();
        this.getInvoices();
      },
      immediate: true,
    },
    options: {
      handler: function() {
        this.getInvoices();
      },
      deep: true,
    },
  },
};
</script>
<style>
.canceled {
  background-color: red !important;
}
</style>
