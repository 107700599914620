<template>
  <div>
    <v-card :loading="loading" class="">
      <v-card-title>
        {{ $t("details") }}
        <v-spacer></v-spacer>
        <back-button></back-button>
        <v-btn color="primary" class="mx-2" @click="orderDialog = !orderDialog">
          <v-icon class="mr-2">mdi-plus</v-icon>
          {{ $t("new-order") }}
        </v-btn>
        <AddOrder
          @updated="
            (_) => {
              orderDialog = _;
            }
          "
          @added="
            (_) => {
              orderDialog = _;
              this.$emit('updateOrders');
            }
          "
          type="RepositoryOrders"
          :dialog="orderDialog"
          :item="{ type: 'repository', item: repository }"
        />
      </v-card-title>
      <v-card-text>
        <v-row v-if="!loading" justify="center">
          <v-col cols="8">
            <v-card outlined>
              <v-card-title>
                <div class="d-flex flex-column">
                  <span>
                    {{ $t('name') }} <b>{{ repository.fullName }}</b></span
                  >
                  <span>
                    {{ $t('region') }} <b>{{ repository.region.name }}</b></span
                  >
                  <span
                    >{{ $t('closest-point') }} : <b>{{ repository.closestPoint }}</b></span
                  >
                  <span
                    >{{ $t('description') }}: <b>{{ repository.description }}</b></span
                  >
                </div>
                <v-spacer></v-spacer>
                <span>
                  {{ $t('status') }}:
                  <v-chip color="info" text-color="white">
                    {{ status(repository.status) }}
                  </v-chip>
                </span>
              </v-card-title>
            </v-card>
            <v-card outlined class="transparent">
              <v-card-title>
                <span>
                  <v-icon class="mr-2">mdi-map-marker</v-icon>
                  {{ $t('gps-location') }}
                </span>
              </v-card-title>
              <v-card-text>
                <div
                  class="d-flex justify-content-center align-items-center mt-1"
                >
                  <GmapMap
                    ref="mapRef"
                    :center="{ lat: 33.312805, lng: 44.361488 }"
                    :zoom="12"
                    @click="mark"
                    map-type-id="terrain"
                    style="width: 100%; height: 50vh"
                  >
                    <GmapMarker
                      ref="myMarker"
                      :position="
                        google &&
                          new google.maps.LatLng(
                            inputs.latitude,
                            inputs.longtude
                          )
                      "
                    />
                  </GmapMap>
                </div>
              </v-card-text>
              <v-card-actions x-large class="d-flex justify-end">
                <v-btn @click="saveMap" large :color="save.color">
                  <v-icon>{{ save.icon }}</v-icon>
                  {{ save.text }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card outlined>
              <v-card-title> {{ $t('license-picture') }} </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-img
                  :src="$service.url + repository.licenseUrl"
                  contain
                ></v-img>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card outlined>
              <v-card-title>
                <span> {{ $t('products') }} </span>
              </v-card-title>
              <v-card-text>
                <v-data-table
                  disable-pagination
                  :loading="loading"
                  :headers="headers"
                  :items="products"
                  :no-data-text="$t('no-data')"
                  :hide-default-footer="true"
                >
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card outlined>
              <v-card-title>
                <span> {{ $t('reports') }} </span>
              </v-card-title>
              <v-card-text>
                <repository-reports
                  :repository="repository"
                  :child="true"
                ></repository-reports>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card outlined>
              <v-card-title>
                {{ $t('orders') }}
              </v-card-title>
              <v-card-text>
                <orders typeFrom="RepositoryOrders" :fromId="repository.id" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { gmapApi } from "vue2-google-maps";
import RepositoryReports from "../../archive/RepositoryReports/RepositoryReports.vue";
import Orders from "../../accounts/orders/Orders.vue";
import BackButton from "../../../../components/BackButton.vue";
import AddOrder from "../../accounts/orders/Add.vue";
export default {
  components: { RepositoryReports, Orders, BackButton, AddOrder },
  data() {
    return {
      repository: {},
      orderDialog: false,
      loading: false,
      inputs: {
        longtude: 33.312805,
        latitude: 44.361488,
      },
      save: {
        text: this.$t('save-map'),
        icon: "mdi-content-save",
        color: "warning",
      },
      products: [],
      headers: [
        { text: this.$t('scientific-name'), value: "product.scientificName" },
        { text: this.$t('trade-name'), value: "product.tradeName" },
        { text: "QTY", value: "qty" },
      ],
    };
  },
  computed: {
    google: gmapApi,
  },
  methods: {
    mark(e) {
      this.inputs.longtude = e.latLng.lng().toString();
      this.inputs.latitude = e.latLng.lat().toString();

      this.$http
        .put("/repositories/putMap/" + this.repository.id, this.inputs)
        .then(() => {
          this.loading = false;
        });
    },
    saveMap() {
      this.$http
        .put("/repositories/putMap/" + this.repository.id, this.inputs)
        .then(() => {
          this.loading = false;
          this.save = {
            text: "Saved !",
            icon: "mdi-content-save",
            color: "success",
          };
          setTimeout(() => {
            this.save = {
              text: "Save Map",
              icon: "mdi-content-save",
              color: "warning",
            };
          }, 3000);
        });
    },
    getRepository() {
      this.loading = true;
      this.$http
        .get("/repositories/" + this.$route.params.id)
        .then((response) => {
          this.repository = response.data.result;
          this.inputs.longtude = response.data.result.longtude;
          this.inputs.latitude = response.data.result.latitude;

          this.$http
            .get("/repositories/getProducts/" + this.$route.params.id)
            .then((res) => {
              this.products = res.data.result;
              this.loading = false;
            });
        });
    },
    status(stat) {
      var ret = "Select";
      if (stat != null) {
        Object.keys(stat).forEach((key) => {
          if (stat[key] == true) {
            //
            ret = key;
          }
        });
      }
      return ret.replace("is", "");
    },
  },
  created() {
    this.getRepository();
  },
};
</script>
