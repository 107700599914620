var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.repository == null && !_vm.child)?_c('div',[_c('h1',[_vm._v("Repository Reports")]),_c('v-row',[_c('v-col',{staticStyle:{"padding-bottom":"0"},attrs:{"cols":"3"}},[_c('label',{attrs:{"for":"se"}},[_vm._v(_vm._s(_vm.$t('search')))]),_c('v-text-field',{staticClass:"mt-2",attrs:{"hide-detials":"auto","label":_vm.$t('search'),"id":"se","solo":"","filled":"","loading":_vm.loading,"clearable":"","append-icon":"mdi-magnify","type":"search"},model:{value:(_vm.options.Search),callback:function ($$v) {_vm.$set(_vm.options, "Search", $$v)},expression:"options.Search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"mt-2",attrs:{"disable-pagination":"","headers":_vm.headers,"items":_vm.reports,"loading":_vm.loading,"hide-default-footer":"","no-data-text":_vm.$t('no-data')},scopedSlots:_vm._u([{key:"item.products",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.tradeName)+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(_vm.$service.formatDate(new Date(item.created)))+" ")])]}}],null,false,3000680863)}),_c('div',{staticClass:"d-flex justify-center"},[_c('v-pagination',{attrs:{"length":parseInt(_vm.total / _vm.options.PageSize) + 1},model:{value:(_vm.options.PageIndex),callback:function ($$v) {_vm.$set(_vm.options, "PageIndex", $$v)},expression:"options.PageIndex"}})],1)],1)],1)],1):_c('div',[_c('v-data-table',{staticClass:"mt-2",attrs:{"disable-pagination":"","headers":_vm.headers,"items":_vm.reports,"loading":_vm.loading,"hide-default-footer":"","no-data-text":_vm.$t('no-data')},scopedSlots:_vm._u([{key:"item.products",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.tradeName)+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(_vm.$service.formatDate(new Date(item.created)))+" ")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }