<template>
  <div>
    <v-dialog persistent v-model="dialog" width="1300">
      <v-card outlined :loading="loading">
        <v-card-title class="text-h5 my-3">
          Make an order
          <v-spacer></v-spacer>
          <v-btn @click="dialog = !dialog" text color="error">
            <f-icon icon="times-circle" />
          </v-btn>
        </v-card-title>
        <v-form :loading="loading" ref="addInvoice">
          <v-divider></v-divider>
          <v-card-text class="mt-2">
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-if="type == 'PharmacyOrders'"
                  clearable
                  v-model="inputs.pharmacyId"
                  :items="pharmacies"
                  :rules="[(v) => !!v || $t('this-field-is-requird')]"
                  :search-input.sync="searchPharmacy"
                  :loading="loading"
                  :disabled="itemDisabled"
                  :no-data-text="
                    searchPharmacy == '' && searchPharmacy == null
                      ? 'Write to search ...'
                      : 'No data..'
                  "
                  outlined
                  item-value="id"
                  chips
                  item-text="fullName"
                  :label="'Select ' + typeText"
                  hide-details="auto"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.fullName"
                      ></v-list-item-title>
                      <v-list-item-subtitle v-if="data.item.status != null">
                        {{ status(data.item.status) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-else-if="type == 'RepositoryOrders'"
                  clearable
                  :disabled="itemDisabled"
                  v-model="inputs.repositoryId"
                  :items="repositories"
                  :rules="[(v) => !!v || $t('this-field-is-requird')]"
                  :search-input.sync="searchRepository"
                  :loading="loading"
                  :no-data-text="
                    searchRepository == '' && searchRepository == null
                      ? 'Write to search ...'
                      : 'No data..'
                  "
                  outlined
                  item-value="id"
                  chips
                  item-text="fullName"
                  :label="'Select ' + typeText"
                  hide-details="auto"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.fullName"
                      ></v-list-item-title>
                      <v-list-item-subtitle v-if="data.item.status != null">
                        {{ status(data.item.status) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-else
                  hide-detials="auto"
                  clearable
                  chips
                  :disabled="itemDisabled"
                  dense
                  v-model="inputs.doctorId"
                  :items="doctors"
                  :rules="[(v) => !!v || $t('this-field-is-requird')]"
                  :search-input.sync="searchDoctor"
                  :loading="loading"
                  :no-data-text="
                    searchDoctor == '' && searchDoctor == null
                      ? 'Write to search ...'
                      : 'No data..'
                  "
                  outlined
                  item-value="id"
                  :label="'Select ' + typeText"
                  item-text="fullName"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="12">
                <table>
                  <thead>
                    <th >Product</th>
                    <th >Quantity</th>
                    <th >Discount</th>
                    <th >Bonus</th>
                    <th v-if="type == 'PharmacyOrders'">
                      Deal Value
                    </th>
                    <th v-if="type == 'PharmacyOrders'" >
                      Other Deal
                    </th>
                    <template v-if="type == 'PharmacyOrders'"
                      ><th >Doctor Deal</th>
                      <th >Doctor Deal Value</th>
                      <th>Doctor</th>
                    </template>
                    <th >Is Cash</th>
                    <th >Notes</th>
                    <th ></th>
                  </thead>
                  <tbody>
                    <template v-for="(prod, i) in inputs.products">
                      <tr :key="i">
                        <td>
                          {{ inputs.products[i].productId.scientificName }}
                        </td>
                        <td>
                          {{ inputs.products[i].qty }}
                        </td>
                        <td>
                          {{ inputs.products[i].discount }}
                        </td>
                        <td>
                          {{ inputs.products[i].bonus }}
                        </td>
                        <td v-if="type == 'PharmacyOrders'">
                          {{ inputs.products[i].dealValue }}
                        </td>
                        <td v-if="type == 'PharmacyOrders'">
                          {{ inputs.products[i].otherDeal }}
                        </td>
                        <template v-if="type == 'PharmacyOrders'">
                          <td>
                            <!-- switch -->
                            {{ inputs.products[i].isDoctorDeal ? "Yes" : "No" }}
                          </td>
                          <td>
                            {{ inputs.products[i].doctorDeal }}
                          </td>
                          <td>
                            {{ inputs.products[i].doctorId.fullName }}
                          </td>
                        </template>
                        
                        <!-- <td class="black--text">Note</td> -->
                        <td>
                          <v-checkbox
                            label="Cash"
                            v-model="inputs.products[i].cash"
                          ></v-checkbox>
                        </td>
                        <td>
                          {{ inputs.products[i].note }}
                        </td>
                        <td>
                          <div class="d-flex align-center">
                            <v-btn
                              @click="removeFromList(i)"
                              color="error"
                              text
                              x-small
                            >
                              <f-icon icon="times-circle" />
                            </v-btn>
                          </div>
                        </td>
                        
                      </tr>
                      <!-- <tr :key="'note' + i">
                        <td class="black--text">Note</td>
                        <td>
                          {{ inputs.products[i].note }}
                        </td>
                        <td>
                          <v-checkbox
                            label="Cash"
                            v-model="inputs.products[i].cash"
                          ></v-checkbox>
                        </td>
                      </tr> -->
                      <v-divider :key="'dd' + i"></v-divider>
                    </template>
                  </tbody>
                </table>
                <div class="d-flex justify-end px-2">
                  <v-btn @click="addProduct = !addProduct" color="primary" text>
                    <v-icon>mdi-plus</v-icon>
                    Add Product
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  hide-details
                  height="50"
                  :label="$t('note')"
                  v-model="inputs.note"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <div class="d-flex align-center justify-end py-3 px-3">
            <v-btn
              color="error"
              class="mx-1"
              outlined
              @click="dialog = !dialog"
            >
              <f-icon icon="times-circle" />
              Cancel</v-btn
            >
            <v-btn color="primary" :loading="loading" @click="addInvoice">
              <v-icon>mdi-plus</v-icon>
              Save</v-btn
            >
          </div>
        </v-form>
      </v-card>
    </v-dialog>



    <!-- Here Work -->

    <v-dialog persistent v-model="addProduct" width="600">
      <v-card outlined :loading="loading">
        <v-card-title class="text-h5 my-3">
          <span class="headline">Add Product to order</span>
          <v-spacer></v-spacer>
          <v-btn @click="addProduct = !addProduct" text color="error">
            <f-icon icon="times-circle" />
          </v-btn>
        </v-card-title>
        <v-form :loading="loading" ref="addProduct">
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  v-model="product.productId"
                  outlined
                  dense
                  :items="batchs"
                  :search-input.sync="searchProduct"
                  :loading="loading"
                  :rules="[(v) => !!v || $t('this-field-is-requird')]"
                  :no-data-text="
                    searchProduct == '' && searchProduct == null
                      ? 'Write to search ...'
                      : 'No data..'
                  "
                  item-text="tradeName"
                  label="Product"
                  return-object
                  hide-details="auto"
                  chips
                  @change="changePrice()"
                >
                  <template v-slot:selection="data">
                    <v-chip v-bind="data.attrs" :input-value="data.selected">
                      {{ data.item.tradeName }}
                      <small class="mx-1">{{ data.item.scientificName }}</small>
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.tradeName }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.scientificName"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  hide-detials="auto"
                  outlined
                  dense
                  label="Quantity"
                  hide-details="auto"
                  v-model="product.qty"
                  :rules="[(v) => v > 0 || $t('this-field-is-requird')]"
                  placeholder="0"
                  :pattern="/^-?\d+\.?\d*$/"
                  id="id"
                ></v-text-field>
                <p
                  :class="
                    product.productId.qtyAvailable < product.qty
                      ? 'error--text'
                      : ''
                  "
                  v-if="product.productId"
                >
                  Available: {{ product.productId.qtyAvailable }}
                </p>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  hide-detials="auto"
                  outlined
                  dense
                  :label="$t('discount')"
                  hide-details="auto"
                  v-model="product.discount"
                  :rules="[(v) => v > -1 || $t('this-field-is-requird')]"
                  placeholder="0"
                  :pattern="/^-?\d+\.?\d*$/"
                  id="id"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  hide-detials="auto"
                  outlined
                  dense
                  hide-details="auto"
                  :label="$t('bonus')"
                  v-model="product.bonus"
                  :rules="[(v) => v > -1 || $t('this-field-is-requird')]"
                  @input="
                    product.bonus = $service.formatCurrency(product.bonus)
                  "
                  placeholder="0"
                  :pattern="/^-?\d+\.?\d*$/"
                  id="id"
                ></v-text-field>
              </v-col>
              <!-- <v-col>
                <v-text-field
                  hide-detials="auto"
                  outlined
                  v-if="product.dealType == 'deal'"
                  label="Deal value"
                  hide-details="auto"
                  v-model="product.dealValue"
                  @input="
                    product.dealValue = $service.formatCurrency(
                      product.dealValue
                    )
                  "
                  placeholder="0"
                  :pattern="/^-?\d+\.?\d*$/"
                  dense
                  id="id"
                ></v-text-field>
              </v-col> -->
              <!-- <v-col>
                <v-checkbox
                  class = "my-0"
                  hide-details="auto"
                  label="Cash"
                  v-model="product.cash"
                ></v-checkbox>
              </v-col> -->
              <!-- <v-col cols="12">
                <v-tabs
                  v-model="dealTab"
                  color="primary"
                  slider-color="primary"
                >
                  <v-tab :class="{ 'text-capitalize': true }">
                    Doctor Deal
                  </v-tab>
                  <v-tab :class="{ 'text-capitalize': true }">
                    Other Deal
                  </v-tab>
                </v-tabs>
              </v-col> -->
              <template v-if="dealTab == 1">
                <v-col v-if="type == 'PharmacyOrders'" cols="6">
                  <v-radio-group
                    hide-details="auto"
                    row
                    dense
                    v-model="product.dealType"
                  >
                    <v-radio label="Deal value" value="deal"></v-radio>
                    <v-radio label="Other Deal" value="other"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col v-if="type == 'PharmacyOrders'" cols="6">
                  <v-text-field
                    hide-detials="auto"
                    outlined
                    v-if="product.dealType == 'deal'"
                    label="Deal value"
                    hide-details="auto"
                    v-model="product.dealValue"
                    @input="
                      product.dealValue = $service.formatCurrency(
                        product.dealValue
                      )
                    "
                    placeholder="0"
                    :pattern="/^-?\d+\.?\d*$/"
                    dense
                    id="id"
                  ></v-text-field>
                  <v-text-field
                    hide-detials="auto"
                    outlined
                    v-if="product.dealType == 'other'"
                    dense
                    label="Other Deal"
                    hide-details="auto"
                    v-model="product.otherDeal"
                    placeholder="0"
                    :pattern="/^-?\d+\.?\d*$/"
                    id="id"
                  ></v-text-field>
                </v-col>
              </template>
              <template v-if="dealTab == 0">
                <v-col v-if="type == 'PharmacyOrders'" cols="12">
                  <v-switch
                    label="Doctor Deal"
                    v-model="product.isDoctorDeal"
                    color="info"
                    hide-details="auto"
                  ></v-switch>
                </v-col>



                <!-- New Dr Dell -->
                <template v-if = "product.isDoctorDeal">
                  <!-- <v-col v-if="type == 'PharmacyOrders'" cols="6">
                    <v-autocomplete
                      hide-detials="auto"
                      clearable
                      label="Deal Type"
                      v-model="product.dealType"
                      return-object
                      :items="dealType_list"
                      outlined
                      item-value="item"
                      hide-details="auto"
                  /></v-col> -->
                  
                  <v-col v-if="type == 'PharmacyOrders'" cols="6">
                    <v-autocomplete
                      hide-detials="auto"
                      clearable
                      label="Doctor"
                      v-model="product.doctorId"
                      return-object
                      :items="doctors"
                      :search-input.sync="searchDoctor"
                      :loading="loading"
                      :no-data-text="
                        searchDoctor == '' && searchDoctor == null
                          ? 'Write to search ...'
                          : 'No data..'
                      "
                      outlined
                      item-value="id"
                      item-text="fullName"
                      hide-details="auto"
                  /></v-col>

                  <v-col v-if="type == 'PharmacyOrders'" cols="6">
                    <v-text-field
                      hide-detials="auto"
                      outlined
                      hide-details="auto"
                      label="Doctor Deal"
                      v-model="product.doctorDeal"
                      @input="
                        product.doctorDeal = $service.formatCurrency(
                          product.doctorDeal
                        )
                      "
                      placeholder="0"
                      :pattern="/^-?\d+\.?\d*$/"
                      id="id"
                    ></v-text-field>
                  </v-col>
                    <v-text-field
                      hide-detials="auto"
                      outlined
                      v-if="product.dealType == 'other'"
                      dense
                      label="Other Deal"
                      hide-details="auto"
                      v-model="product.otherDeal"
                      placeholder="0"
                      :pattern="/^-?\d+\.?\d*$/"
                      id="id"
                    ></v-text-field>
                    <v-col cols="12">
                      <v-text-field
                        hide-detials="auto"
                        outlined
                        :label="$t('note')"
                        hide-details="auto"
                        v-model="product.note"
                      ></v-text-field>
                    </v-col>
                </template>
              </template>
            </v-row>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn @click="addProduct = !addProduct" color="error" outlined>
            <v-icon>mdi-trash-can</v-icon>
            Canecl</v-btn
          >
          <v-btn @click="addToList" color="primary">
            <v-icon>mdi-check</v-icon>
            Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Here Work -->
  </div>
</template>
<script>
export default {
  props: ["dialog", "type", "item"],
  computed: {
    typeText() {
      return this.type.replace("Orders", "");
    },
  },
  data() {
    return {
      addProduct: false,
      dealTab: 0,
      dealType_list:["deal","other"],
      product: {
        productId: "",
        qty: 0,
        price: 0,
        bonus: 0,
        discount: 0,
        dealValue: 0,
        otherDeal: "",
        isDoctorDeal: false,
        cash: true,
        doctorDeal: 0,
        doctorDealNote: "",
        doctorId: "",
        dealType: "other", //deal / other
        note: "",
      },
      inputs: {
        pharmacyId: "",
        doctorId: "",
        repositoryId: "",
        note: "",
        products: [],
      },
      itemDisabled: false,
      batchs: [],
      pharmacies: [],
      repositories: [],
      doctors: [],
      loading: false,
      searchPharmacy: "",
      searchProduct: "",
      searchDoctor: "",
      searchRepository: "",
    };
  },
  methods: {
    checkItem() {
      if (this.item != undefined) {
        if (this.item.type == "doctor") {
          this.doctors.push(this.item);
          this.inputs[this.item.type + "Id"] = this.item.item.id;
        } else if (this.item.type == "pharmacy") {
          this.pharmacies.push(this.item);
          this.inputs[this.item.type + "Id"] = this.item.item.id;
        } else if (this.item.type == "repository") {
          this.repositories.push(this.item);
          this.inputs[this.item.type + "Id"] = this.item.item.id;
        }
        this.itemDisabled = true;
      }
    },
    status(stat) {
      var ret = "Select";
      Object.keys(stat).forEach((key) => {
        if (stat[key] == true) {
          //
          ret = key;
        }
      });
      return ret.replace("is", "");
    },
    changePrice() {
      this.product.price = this.product.productId.price;
      // var s = this.batchs.find(v => v.id == this.inputs.products[i].productId);
      //
    },
    addInvoice() {

      console.log("this.product")
      console.log(this.product)

      if (this.$refs.addInvoice.validate()) {
        this.loading = true;
        var body = this.inputs;
        for (let i = 0; i < body.products.length; i++) {
          body.products[i] = {
            qty: body.products[i].qty.toString().replace(/,/g, ""),
            bonus: body.products[i].bonus.toString().replace(/,/g, ""),
            doctorDeal: body.products[i].doctorDeal.toString().replace(/,/g, ""),
            productId: body.products[i].productId.id,
            price: body.products[i].price.toString().replace(/,/g, ""),
            discount: body.products[i].discount.toString().replace(/,/g, ""),
            doctorId: body.products[i].doctorId.id,
            dealValue: body.products[i].dealValue.toString().replace(/,/g, ""),
            otherDeal: body.products[i].otherDeal,
            isDoctorDeal: body.products[i].isDoctorDeal,
            note: body.products[i].note,
            cash: body.products[i].cash,
            doctorDealNote: body.products[i].doctorDealNote,
          };
        }
        this.$http
          .post(`/${this.type}/post`, this.inputs)
          .then(() => {
            this.$emit("added", !this.dialog);
            this.dialog = false;
          })
          .catch((e) => {
            this.$store.commit("UPDATE_SNACKBAR", true);
            this.$store.commit(
              "UPDATE_SNACKBAR_MESSAGE",
              e.response.data.message +
                "<br />" +
                typeof e.response.data.errors ==
                "array"
                ? e.response.data.errors.join(" ")
                : ""
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    addToList() {
      this.inputs.products.push(this.product);
      this.addProduct = false;
    },
    removeFromList(index) {
      this.inputs.products.splice(index, 1);
    },
  },
  created() {
    this.loading = true;
    this.$http.get("/Doctors").then((res) => {
      this.doctors = res.data.data;
    });
  },
  watch: {
    addProduct() {
      this.product = {
        productId: "",
        qty: 0,
        price: 0,
        bonus: 0,
        discount: 0,
        dealValue: 0,
        otherDeal: "",
        isDoctorDeal: true,
        cash: true,
        doctorDeal: 0,
        doctorDealNote: "",
        doctorId: "",
        dealType: "deal",
        note: "",
      };
    },
    dialog() {
      this.$emit("updated", this.dialog);
      if (this.dialog == false) {
        this.itemDisabled = false;
      } else {
        this.checkItem();
      }
      let get = "Pharmacies";
      if (this.type == "RepositoryOrders") get = "Repositories";
      else if (this.type == "DoctorOrders") get = "Doctors";

      this.$http
        .get("/" + get, {
          params: {
            CompanyId: this.$store.state.company.id,
          },
        })
        .then((res) => {
          this.pharmacies = res.data.data;
          this.$http.get("/Products").then((res) => {
            this.batchs = res.data.data;
            this.loading = false;
          });
        });
    },
    searchProduct() {
      if (this.searchProduct != "" && this.searchProduct != null) {
        this.batchs = [];
        this.loading = true;
        this.$http.get("/Products?Search=" + this.searchProduct).then((res) => {
          this.batchs = res.data.data;
          this.loading = false;
        });
      }
    },
    searchPharmacy() {
      if (this.searchPharmacy != "" && this.searchPharmacy != null) {
        this.pharmacies = [];
        this.loading = true;
        this.$http
          .get("/Pharmacies?Search=" + this.searchPharmacy, {
            params: {
              OfficeId: this.$store.state.userInfo.office.id,
              CompanyId: this.$store.state.userInfo.userData.companyId,
            },
          })
          .then((res) => {
            this.pharmacies = res.data.data;
            this.loading = false;
          });
      }
    },
    searchRepository() {
      if (this.searchRepository != "" && this.searchRepository != null) {
        this.repositories = [];
        this.loading = true;
        this.$http
          .get("/repositories?Search=" + this.searchRepository, {
            params: {
              OfficeId: this.$store.state.userInfo.office.id,
              CompanyId: this.$store.state.userInfo.userData.companyId,
            },
          })
          .then((res) => {
            this.repositories = res.data.data;
            this.loading = false;
          });
      }
    },
    searchDoctor() {
      if (this.searchDoctor !== "" && this.searchDoctor !== null) {
        this.doctors = [];
        this.loading = true;
        this.$http.get("/Doctors?Search=" + this.searchDoctor).then((res) => {
          this.doctors = res.data.data;
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: none;
  border-spacing: 0;
  margin-bottom: 20px;
  border: 1px solid #ffffff;
}
thead {
  background-color: #c7c7c7;
  color: #ffffff;
  border-radius: 10px;
}
th {
  border: 1px solid #ffffff;
  padding: 2px;
  color: #000000;
}
td {
  /* color: #ffffff !important; */
  border: 1px solid #ffffff;
  padding: 5px;
}
</style>
