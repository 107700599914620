<template>
    <div>
        <v-btn color="primary" outlined @click="back">
            <v-icon left>mdi-arrow-left</v-icon>
            {{ $t('back') }}
        </v-btn>
    </div>
</template>
<script>
export default {
    methods:{
        back(){
            this.$router.go(-1);
        }
    }
}
</script>