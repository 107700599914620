var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("details"))+" "),_c('v-spacer'),_c('back-button'),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.orderDialog = !_vm.orderDialog}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("new-order"))+" ")],1),_c('AddOrder',{attrs:{"type":"RepositoryOrders","dialog":_vm.orderDialog,"item":{ type: 'repository', item: _vm.repository }},on:{"updated":function (_) {
            _vm.orderDialog = _;
          },"added":function (_) {
            _vm.orderDialog = _;
            this$1.$emit('updateOrders');
          }}})],1),_c('v-card-text',[(!_vm.loading)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('name'))+" "),_c('b',[_vm._v(_vm._s(_vm.repository.fullName))])]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('region'))+" "),_c('b',[_vm._v(_vm._s(_vm.repository.region.name))])]),_c('span',[_vm._v(_vm._s(_vm.$t('closest-point'))+" : "),_c('b',[_vm._v(_vm._s(_vm.repository.closestPoint))])]),_c('span',[_vm._v(_vm._s(_vm.$t('description'))+": "),_c('b',[_vm._v(_vm._s(_vm.repository.description))])])]),_c('v-spacer'),_c('span',[_vm._v(" "+_vm._s(_vm.$t('status'))+": "),_c('v-chip',{attrs:{"color":"info","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.status(_vm.repository.status))+" ")])],1)],1)],1),_c('v-card',{staticClass:"transparent",attrs:{"outlined":""}},[_c('v-card-title',[_c('span',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-map-marker")]),_vm._v(" "+_vm._s(_vm.$t('gps-location'))+" ")],1)]),_c('v-card-text',[_c('div',{staticClass:"d-flex justify-content-center align-items-center mt-1"},[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"50vh"},attrs:{"center":{ lat: 33.312805, lng: 44.361488 },"zoom":12,"map-type-id":"terrain"},on:{"click":_vm.mark}},[_c('GmapMarker',{ref:"myMarker",attrs:{"position":_vm.google &&
                        new _vm.google.maps.LatLng(
                          _vm.inputs.latitude,
                          _vm.inputs.longtude
                        )}})],1)],1)]),_c('v-card-actions',{staticClass:"d-flex justify-end",attrs:{"x-large":""}},[_c('v-btn',{attrs:{"large":"","color":_vm.save.color},on:{"click":_vm.saveMap}},[_c('v-icon',[_vm._v(_vm._s(_vm.save.icon))]),_vm._v(" "+_vm._s(_vm.save.text)+" ")],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('license-picture'))+" ")]),_c('v-divider'),_c('v-card-text',[_c('v-img',{attrs:{"src":_vm.$service.url + _vm.repository.licenseUrl,"contain":""}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('span',[_vm._v(" "+_vm._s(_vm.$t('products'))+" ")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"disable-pagination":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.products,"no-data-text":_vm.$t('no-data'),"hide-default-footer":true}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('span',[_vm._v(" "+_vm._s(_vm.$t('reports'))+" ")])]),_c('v-card-text',[_c('repository-reports',{attrs:{"repository":_vm.repository,"child":true}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('orders'))+" ")]),_c('v-card-text',[_c('orders',{attrs:{"typeFrom":"RepositoryOrders","fromId":_vm.repository.id}})],1)],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }